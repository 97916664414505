import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';
import { StorageService } from 'src/app/services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/forms/confirm-dialog/confirm-dialog.component';
import { EntradaEcomComponent, EventOnValidIngressoEcom } from 'src/app/forms/ingreso-ecom/entrada-ecom.component';
import { BackendIngresoEcom } from 'src/app/services/ingresoEcom/ingreso-ecom.service';

@Component({
  selector: 'app-mobile-entrada-ecom',
  templateUrl: './mobile-entrada-ecom.component.html',
  styleUrls: ['./mobile-entrada-ecom.component.scss']
})
export class MobileEntradaEcomComponent implements OnInit {
  @ViewChild(EntradaEcomComponent) 
  formControlIngressoEcom!: EntradaEcomComponent;

  funcion: Funcion | null = null;
  sistema: string = '59';
  paso: number = 1;
  subtitulo: string = 'Seguridad';
  // Seguridad
  cliente: IdsDescr | null = null;
  cuenta: IdDescr | null = null;
  subcuenta: IdDescr | null = null;
  //Valores Componente hijo
  valid = false;
  ecomDetail: any;
  selectedCalle: any;
  selectedColumn: any;
  selectedNivel: any;

  constructor(
    private storage: StorageService,
    private router: Router,
    private back: BackendIngresoEcom,
    private msg: NotificationService,
    private sound: SoundService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.funcion = this.storage.getFuncion(
      'mobile_ingresso_ecom'
    );
    
    if (this.funcion) {
      this.sistema = this.funcion.sistema.id;
    }
  }

  onValidIngressoEcom($event: EventOnValidIngressoEcom) {
    //this.valid = $event.valid;
    this.valid = true; 
  }

  onClick() {
    if (this.paso < 2) {
      this.setPaso(this.paso + 1);
    } else {
      this.validations();
    }
  }
  validations() {
    const ecomVin = this.formControlIngressoEcom.ecomDetail;
    if (!this.formControlIngressoEcom.selectedNivel) return this.msg.showError('localização não encontrada');
    if (!ecomVin.id) this.msg.showError('Não é possível processar.');

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: 'Quer mesmo realizar este processo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.procesar();
        return;
      }
    })

  }

  procesar() {

    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      vin: this.formControlIngressoEcom.ecomDetail.id
    };

    this.back.procesar(payload).subscribe(
      (data) => {
        if (data) {
          this.setPaso(2);
          this.ingressar();
        } else {
          if (data ) {
            this.formControlIngressoEcom.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          } else {
            this.msg.showError('Error inesperado', 'Error');
            this.formControlIngressoEcom.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.msg.showError(error.statusText, 'Error inesperado');
        this.sound.playBeepError();
      }
    );
  }

  ingressar(){
    
    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subCuenta: this.subcuenta?.id,
      vin: this.formControlIngressoEcom.ecomDetail.id,
      calle: this.formControlIngressoEcom.selectedCalle,
      column:this.formControlIngressoEcom.selectedColumn,
      nivel:this.formControlIngressoEcom.selectedNivel,
      funcion: this.funcion?.id,
      url: "",
      inserta: false,
      transacion: "2264",
    };
    
    console.log(payload);

    this.back.ingressar(payload).subscribe(
      (data) => {
        console.log(data)
        if (data) {
          this.msg.showSuccess('Processo realizado com sucesso!');
          this.setPaso(2);
          this.sound.playBeepOk();
          this.formControlIngressoEcom.ClearForm();

        } else {
          if (data ) {
            this.formControlIngressoEcom.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          } else {
            this.msg.showError('Error inesperado', 'Error');
            this.formControlIngressoEcom.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          }
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.msg.showError(error.error.err.message);
        this.msg.showError(error.statusText, 'Error inesperado');
        this.sound.playBeepError();
      }
    );
  }

  setPaso(nuevoPaso: number) {
    this.valid = false;
    this.paso = nuevoPaso;
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:
        this.subtitulo = `Ingresso ECOM - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`;
        break;

      default:
        break;
    }
  }

  onCancel = () => {
    this.setPaso(this.paso - 1);
    if (this.paso == 0) this.router.navigate(['/']);
  };

  onValidSeguridad($event: SeguridadEvent) {
    if ($event.valid) {
      this.cliente = $event.cliente;
      this.cuenta = $event.cuenta;
      this.subcuenta = $event.subcuenta;
    }
    //this.valid = $event.valid;

    //inicio
    this.valid = true;
    this.cliente = $event.cliente;
    this.cuenta = $event.cuenta;
    this.subcuenta = $event.subcuenta;
    //fin (esto no deberia ir cuando se valide de manera correcta)
  }


}

