import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { IdsDescr } from '../../model/iddescr';
import { Usuario } from '../../model/usuario';
import { environment } from '../../../environments/environment';

type responseVin = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  stockPlaya: any;
};

type responseOperacion = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  operacionData: any;
  operacionesPendientes: boolean;
  permiteRegistrarOperacion: boolean;
  proveedores: IdsDescr[];
  proveedor: any;
  lineasOperacion: any[];
  inhabilitarProveedor: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class BackendIngresoEcom {
  private usrServer: String = environment.urlBackend;
  private urlMobile: String = this.usrServer + 'mobile/';

  constructor(private httpClient: HttpClient) { }

  public findEcomBy(payload): Observable<responseVin> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findEcomBy';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get<responseVin>(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        vin: payload.vin,
        transation: payload.transacion
      }
    });
  }

  public findCalle(payload) {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findCalle';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        subCuenta: payload.subCuenta.id
      }
    });
  }

  public findColumn(payload): Observable<responseVin> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findColumn';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get<responseVin>(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        subCuenta: payload.subCuenta.id,
        calle: payload.calle
      }
    });
  }

  public findNivel(payload): Observable<responseVin> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findNivel';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get<responseVin>(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        subCuenta: payload.subCuenta.id,
        calle: payload.calle,
        column: payload.column
      }
    });
  }

  public findMarket(idMercado): Observable<responseVin> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findMarket';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get<responseVin>(url, {
      headers: { authorization: `Bearer ${token}` },
      params: { pais: (parsedUser.pais.id).toString(),
        codMercado: idMercado
       }
    });
  }

  public findOrigen(idOrigen): Observable<responseVin> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findOrigen';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get<responseVin>(url, {
      headers: { authorization: `Bearer ${token}` },
      params: { 
        pais: (parsedUser.pais.id).toString(),
        codOrigen: idOrigen
       }
    });
  }

  public findMarca(payload): Observable<responseVin> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findMarca';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get<responseVin>(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        activo: payload.activo,
        codMarca: payload.codMarca
      }
    });
  }

  public findModelo(payload) {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findModelo';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        marca: payload.marca,
        codModelo: payload.codModelo
      }
    });
  }

  public findColor(payload) {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findColor';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        marca: payload.marca,
        color: payload.color
      }
    });
  }

  public findDestination(codDestination: string) {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findDestination';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        codDestination: codDestination
      }
    });
  }

  public findGenero(payload) {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/findGender';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        marca: payload.marca
      }
    });
  }

  public procesar(payload: any) {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/flujoUyEcom';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        vin: payload.vin
      }
    });
  }

  public ingressar(payload: any) {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'ecom/ingressar';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;

    return this.httpClient.get(url, {
      headers: { authorization: `Bearer ${token}` },
      params: {
        pais: (parsedUser.pais.id).toString(),
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        subCuenta: payload.subCuenta,
        vin: payload.vin,
        calle: payload.calle,
        column: payload.column,
        nivel: payload.nivel,
        funcion: payload.funcion,
        url: payload.url,
        inserta: payload.inserta,
        transacion: payload.transacion
      }
    });
  }

}
